.ticket_information__modal {
    width: 33%;
    height: 80%;
    position: fixed;
    top: 10%;
    left: 33%;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;

    .ticket_information__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .ticket_information__header {
            width: 90%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 40px;
            margin-right: 3%;

            .ticket_information__divider {
                width: 20%;
            }

            .ticket_information__title {
                width: 50%;
                display: flex;
                justify-content: center;

                h2 {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    color: $dark-blue;
                }
            }


            .ticket_information__close {
                width: 20%;
                display: flex;
                justify-content: flex-end;

                img {
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                }
            }
        }

        .ticket_information__content_note {
            margin-bottom: 31px !important;
        }

        .ticket_information__content {
            width: 90%;
            margin-right: 3%;
            margin-bottom: 40px;

            span {
                font-weight: 400;
                font-size: 20px;
                line-height: 23px;
                color: $contacts-gray;
            }

            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 23px;
                color: $black;
                margin: 0;
            }

            .ticket_information__content_action {
                color: $ticket-orange;
                margin-top: 10px;
            }

            .ticket_information__content_spacing {
                margin-top: 10px;
            }

            .ticket_information__dropdown_container {
                width: 55%;
                margin-top: 10px;
                position: relative;

                .ticket_information__selected {
                    width: 100%;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: $red;
                    border-radius: 10px;
                    cursor: pointer;
    
                    .ticket_information__divider {
                        width: 20%;
                    }

                    .ticket_information__selected_container {
                        width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: $white;
                        }
                    }
    
                    .ticket_information__arrow_container {
                        width: 20%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
    
                        img {
                            width: 14px;
                            height: 7px;
                            padding-right: 24px;
                        }
                    }
                }

                .ticket_information__selected__0 {
                    background: $red;
                }

                .ticket_information__selected__1 {
                    background: $minute-green;
                }

                .ticket_information__selected__2 {
                    background: $orange;
                }
                
                .ticket_information__selected__3 {
                    background: $orange;
                }
                
                .ticket_information__selected__4 {
                    background: $orange;
                }
                
                .ticket_information__selected__5 {
                    background: $orange;
                }
                
                .ticket_information__selected__6 {
                    background: $orange;
                }
            
                .ticket_information__dropdown {
                    width: 100%;
                    height: 210px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background: $dark-gray;
                    border-radius: 10px;
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    z-index: 2;
            
                    .ticket_information__dropdown__selected_type {
                        width: 100%;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-radius: 10px;
            
                        .ticket_information__divider {
                            width: 20%;
                            height: 100%;
                        }
                
                        .ticket_information__selected_container {
                            width: 50%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
    
                            span {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 19px;
                                color: $white;
                            }
                        }
        
                        .ticket_information__arrow_container {
                            width: 20%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
        
                            img {
                                width: 14px;
                                height: 7px;
                                padding-right: 24px;
                            }
                        }
                    }
            
                    .ticket_information__dropdown_divider {
                        width: 80%;
                        border: 0.5px solid $select-gray;
                        // margin-top: 5px;
                        // margin-bottom: 5px;
                    }
            
                    .ticket_information__dropdown_item {
                        width: 100%;
                        max-height: 24px;
                        display: flex;
                        flex-direction: column-reverse;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                        margin-bottom: 5px;
            
                        span {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: $white;
                        }

                        .ticket_information__dropdown_divider {
                            width: 80%;
                            border: 0.5px solid $select-gray;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
}