.call__wrapper {
    // width: 37vw;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    .call__contact_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .call__divider {
            width: 20%;
        }

        .call__input_container {
            // min-width: 100px;
            width: 37vw;
            min-height: 57px;
            height: fit-content;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            position: relative;
            border: 1px solid $black;
            box-sizing: border-box;
            border-radius: 14px;
            padding-left: 10px;
            padding-right: 10px;
        }

        .call__timer {
            width: 20%;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $dark-green;
            border-radius: 14px;

            span {
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: $white;
            }
        }
    }

    .call__call_button {
        width: 37vw;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $black;
        border-radius: 10px;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $white;
        border: none;
        cursor: pointer;
        margin-bottom: 30px;

        img {
            margin-right: 2%;
        }
    }

    button:disabled {
        background: $gray;
        cursor: default;
    }

    .call__in_call_button_container {
        width: 37vw;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        
        .call__leave_call_button {
            width: 100%;
            height: 100%;
            background: $orange;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $white;
            border: none;
            cursor: pointer;
        }

        .call__join_call_button {
            width: 100%;
            height: 100%;
            background: $dark-green;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $white;
            border: none;
            cursor: pointer;
        }

        .call__end_call_button {
            width: 100%;
            height: 100%;
            background: $red;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $white;
            border: none;
            cursor: pointer;
            margin-left: 7%;
        }

        .call__call_button:last-of-type {
            width: 100%;
            height: 100%;
            background: $black;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $white;
            border: none;
            cursor: pointer;
        }

        .call__call_button:last-of-type {
            margin-left: 7%;
        }
    }
}