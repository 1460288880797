.subscription_plan_form__container {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 10px;
    padding-left: 2.5%;
    margin-bottom: 20px;

    h2 {
        font-weight: 400;
        font-size: 40px;
        line-height: 47px;
        color: $black;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input[type=number] {
        -moz-appearance: textfield;
    }

    input {
        width: 40%;
        height: 56px;
        border: 1px solid $black;
        box-sizing: border-box;
        border-radius: 10px;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $bolded-gray;
        margin-bottom: 10px;
        padding-left: 10px;
    }

    input:focus {
        outline: none;
    }

    .subscription_plan_form__name_input {
        color: $black;
    }

    .subscription_plan_form__prices_container {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 40px;

        .subscription_plan_form__price_container {
            width: 100%;
            display: flex;
            position: relative;

            .subscription_plan_form__dollar {
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: $bolded-gray;
                position: absolute;
                top: 18%;
                left: 10px;
            }

            .subscription_plan_form__type {
                font-weight: 400;
                font-size: 21px;
                line-height: 25px;
                color: $bolded-gray;
                position: absolute;
                top: 18%;
                right: 10px;
            }

            input {
                width: 100%;
                padding-left: 30px;
            }
        }
    }

    button {
        width: 40%;
        height: 56px;
        background: $black;
        border: none;
        border-radius: 10px;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $white;
        margin-top: 30px;
        margin-bottom: 25px;
        cursor: pointer;
    }
}