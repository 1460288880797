.message__wrapper {
    width: 37vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    .message__input_container {
        min-width: 100px;
        width: 100%;
        min-height: 57px;
        height: fit-content;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        position: relative;
        border: 1px solid $black;
        box-sizing: border-box;
        border-radius: 14px;
        margin-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }

    textarea {
        width: 100%;
        height: 140px;
        background: $white;
        border: 1px solid $black;
        box-sizing: border-box;
        border-radius: 14px;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: $black;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
    }

    textarea::placeholder {
        color: $gray;
    }

    textarea:focus {
        outline: none;
    }

    button {
        width: 37vw;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $black;
        border-radius: 10px;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $white;
        border: none;
        cursor: pointer;
        margin-bottom: 30px;

        img {
            margin-right: 2%;
        }
    }

    button:disabled {
        background: $gray;
        cursor: default;
    }
}