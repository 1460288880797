.note_modal__modal {
    width: 90%;
    height: 400px;
    position: fixed;
    top: 6%;
    left: 5%;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;

    .note_modal__wrapper {
        display: flex;
        justify-content: space-evenly;

        .note_modal__caller {
            width: 22.5%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            p {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: $black;
                margin-top: 40px;
                margin-bottom: 10px;
            }

            span {
                font-weight: 500;
                font-size: 18px;
                line-height: 29px;
                color: $dark-blue;
            }
        }

        .note_modal__input {
            width: 45%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: $black;
                margin-top: 40px;
                margin-bottom: 20px;
            }

            textarea {
                width: 100%;
                height: 190px;
                background: $white;
                border: 1px solid $dark-gray;
                box-sizing: border-box;
                border-radius: 14px;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: $black;
                padding: 15px;
            }

            textarea::placeholder {
                color: $gray;
            }

            textarea:focus {
                outline: none;
            }

            .note_modal__button_container {
                width: 100%;
                height: 56px;
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                margin-bottom: 40px;

                button {
                    width: 45%;
                    height: 56px;
                    background: $dark-gray;
                    border-radius: 20px;
                    border: none;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28px;
                    color: $white;
                    cursor: pointer;
                }

                .note_modal__save {
                    background: $black;
                }
            }
        }

        .note_modal__close {
            width: 22.5%;
            height: 100%;
            display: flex;
            justify-content: flex-end;

            img {
                width: 50px;
                height: 50px;
                margin-top: 20px;
                cursor: pointer;
            }
        }
    }
}