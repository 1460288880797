// Variables
@import "./variables.scss";

// Components
@import "../components/Dashboard/Dashboard.scss";
@import "../components/MainHeader/MainHeader.scss";
@import "../components/CCPPortal/CCPPortal.scss";
@import "../components/Autocomplete/Autocomplete.scss";
@import "../components/SelectedContact/SelectedContact.scss";
@import "../components/CreditCardForm/CreditCardForm.scss";
@import "../components/InfoLabel/InfoLabel.scss";
@import "../components/AddressInfoLabel/AddressInfoLabel.scss";

// Modal Components
@import "../components/Modals/IncomingCallModal/IncomingCallModal.scss";
@import "../components/Modals/CustomerAuthModal/CustomerAuthModal.scss";
@import "../components/Modals/ContactNumberSelectionModal/ContactNumberSelectionModal.scss";
@import "../components/Modals/CreditCardNeededModal/CreditCardNeededModal.scss";
@import "../components/Modals/TicketSummaryModal/TicketSummaryModal.scss";
@import "../components/Modals/LoaderModal/LoaderModal.scss";
@import "../components/Modals/NoteModal/NoteModal.scss";
@import "../components/Modals/ContactModal/ContactModal.scss";

// Action Components
@import "../components/Actions/AddMinutes/AddMinutes.scss";
@import "../components/Actions/BoostPlan/BoostPlan.scss";
@import "../components/Actions/Call/Call.scss";
@import "../components/Actions/ContactListing/ContactListing.scss";
@import "../components/Actions/Message/Message.scss";
@import "../components/Actions/ReassignTicket/ReassignTicket.scss";
@import "../components/Actions/VoiceMessage/VoiceMessage.scss";

// Templates
@import "../templates/AppTemplate/AppTemplate.scss";
@import "../templates/InCallModal/InCallModal.scss";

// Pages
@import "../pages/Analytics/AnalyticsPage.scss";
@import "../pages/Employees/EmployeesPage.scss";
@import "../pages/CreateEmployee/CreateEmployeePage.scss";
@import "../pages/SubscriptionPlan/SubscriptionPlanPage.scss";
@import "../pages/TicketManagement/TicketManagementPage.scss";
@import "../pages/ResetPassword/ResetPassword.scss";
@import "../pages/LoggedOut/LoggedOutPage.scss";
@import "../pages/EditProfile/EditProfilePage.scss";

body {
    overflow-x: hidden;
} // ovo ti resava whitespace na celih app (trebalo bi) ako bude negde jos frka javljaj ....pitao sam tismu da li igde postoji scrool kaze ne tako da ti je ovo u redu resenje

.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: $black, $alpha: 0.75);
    z-index: 998;
}

.modal__scroll_component {
    width: 100%;
    height: 90vh;
    overflow-y: auto;
}

.modal__scroll_component__ticket_information {
    width: 100%;
    height: 80vh;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.modal__scroll_component__ticket_information::-webkit-scrollbar {
    width: 0;
    height: 0;
}

// ::-webkit-scrollbar {
//     width: 30px;
// }

// ::-webkit-scrollbar-thumb {
//     background: $black;
//     border-radius: 30px;
// }

// ::-webkit-scrollbar-track-piece {
//     background:transparent;
// }