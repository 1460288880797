.selected_contact__wrapper {
    width: 48%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $background-gray;
    border-radius: 20px;
    z-index: 4;

    .selected_contact__picture {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $black;
        cursor: default;
        margin-right: auto;
    }

    .selected_contact__close {
        width: 25px;
        height: 25px;
        margin-right: 5px;
        cursor: pointer;
    }
}

.selected_contact__red {
    background: $red !important;
}

.selected_contact__gray {
    background: $background-gray;
}