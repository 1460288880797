.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 38px;
    
    .header__left {
        color: $bolded-gray;
        font-size: 20px;
        line-height: 29px;
        font-weight: 500;
    }

    .header__right {
        display: flex;
        width: 30%;
        height: 30px;
        justify-content: flex-end;
        padding-right: 20px;

        .header_status {
            width: 100px;
            height: 100%;
            // background-color: $status-green;
            // border: 1px solid $dark-green;
            // box-sizing: border-box;
            // border-radius: 14px;
            // display: flex;
            // align-items: center;
            // cursor: default;

            select {
                width: 100%;
                height: 100%;
                background-color: $status-green;
                border: 1px solid $dark-green;
                box-sizing: border-box;
                border-radius: 14px;
                display: flex;
                align-items: center;
                cursor: default;
                padding-left: 15px;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: $white;
            }

            select:focus {
                outline: none;
            }

            .select_status__2 {
                background-color: $reassign-orange;
                border: 1px solid $orange;
            }

            .select_status__3 {
                background-color: $occupied-red;
                border: 1px solid $red;
            }

            p {
                padding-left: 15px;
                padding-right: 17px;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: $white;
            }
        }

        .header_divider {
            display: flex;
            align-items: center;
            border: 1px solid $bolded-gray;
            transform: rotate(90);
            margin-left: 15px;
            margin-right: 15px;
        }

        .header_user {
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            cursor: pointer;

            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: $bolded-gray;
                margin-right: 15px;
                // cursor: default;
            }

            .header__dropdown_menu {
                width: 155%;
                height: 102px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: $white;
                border: 1px solid $black;
                box-sizing: border-box;
                border-radius: 5px;
                position: absolute;
                top: 50px;
                left: 0;
                z-index: 4;

                .header__dropdown_menu__item {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    p {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                        color: $bolded-gray;
                        margin: 0;
                    }
                }

                hr {
                    width: 100%;
                    border-top: 1px solid $black;
                    border-bottom: none;
                    border-left: none;
                    border-right: none;
                    margin: 0;
                }
            }
        }

        .header_profile_image {
            display: flex;
            align-items: center;
            margin-left: 15px;
        }
    }
}