.dashboard {
    height: 100%;
    width: 15vw;
    position: fixed;
    background-color: $black;
    
    .dashboard_logo {
        padding-left: 40px;
        padding-top: 30px;
        width: 70%;
        height: 74px;
        
        .dashboard_logo--image {
            width: 100%;
            object-fit: contain;
        }
    }

    .dashboard_role {
        padding-left: 40px;
        padding-top: 25px;
        padding-bottom: 75px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        p {
            color: $dark-gray;
        }
    }

    .dashboard_menu_item {
        padding-left: 45px;
        padding-bottom: 40px;

        .dashboard_menu_item__content {
            display: flex;
            align-items: center;

            .dashboard_menu_item__text {
                font-weight: 500;
                font-size: 16px;
                line-height: 29px;
                letter-spacing: 0.43px;
                color: $gray;
            }
            
            .dashboard_menu_item__button {
                padding-left: 15px;
                width: 50px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $contacts-gray;
                    color: $black;
                    font-weight: 700;
                    font-size: 11px;
                    border: none;
                    box-shadow: 0px 2px 6px rgba(47, 83, 151, 0.1);
                    border-radius: 3px;
                }
            }

            a {
                text-decoration: none;
            }
        }

        .dashboard_menu_item__content--active {
            display: flex;
            position: relative;
            align-items: center;
    
            .dashboard_menu_item__text {
                font-weight: 500;
                font-size: 16px;
                line-height: 29px;
                letter-spacing: 0.43px;
                color: $white;
            }
            
            .dashboard_menu_item__button {
                padding-left: 15px;
                width: 50px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $contacts-gray;
                    color: $black;
                    font-weight: 700;
                    font-size: 11px;
                    border: none;
                    box-shadow: 0px 2px 6px rgba(47, 83, 151, 0.1);
                    border-radius: 3px;
                }
            }

            a {
                text-decoration: none;
            }
        }

        .dashboard_menu_item__content--active::after {
            content: '';
            position: absolute;
            left: 96%;
            top: 20%;
            width: 0;
            height: 0;
            border-bottom: 10px solid transparent;
            border-right: 10px solid $light-gray;
            border-top: 10px solid transparent;
            clear: both;
        }

    }
}
