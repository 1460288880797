.customer_auth__modal {
    min-width: 60%;
    min-height: 340px;
    // max-height: 375px;
    position: fixed;
    top: 10%;
    left: 20%;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;

    .customer_auth__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .customer_auth__top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            img {
                width: 68px;
                height: 68px;
                margin-left: 5%;
                margin-right: 5%;
                cursor: pointer;
            }
    
            .customer_auth__info {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                p {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    color: $dark-blue;
                    margin-top: 30px;
                    margin-bottom: 10px;
                }

                span {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 29px;
                }
            }
        }
    }

    .customer_auth__next_button {
        width: 100%;
        height: 56px;
        background: $black;
        border-radius: 10px;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $white;
        border: none;
        cursor: pointer;
        margin-bottom: 20px;
    }

    .customer_auth__next_button:disabled {
        background: $gray;
        cursor: default;
    }

    .customer_auth__input {
        width: 100%;
        height: 56px;
        background: rgba($color: $white, $alpha: 0.5);
        border: 1px solid $black;
        box-sizing: border-box;
        border-radius: 10px;
        padding-left: 5%;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $black;
        margin-bottom: 20px;
    }

    .customer_auth__input:focus {
        outline: none;
    }

    .customer_auth__input::placeholder {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $gray;
    }

    .customer_auth__email_input {
        display: flex;
        flex-direction: column;
        width: 28%;

        .customer_auth__enroll {
            width: 100%;
            height: 56px;
            background: $black;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $white;
            border: none;
            cursor: pointer;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .customer_auth__enroll:disabled {
            background-color: $gray;
        }
    }

    .customer_auth__passphrase_input {
        display: flex;
        flex-direction: column;

        span {
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: $gray;
            margin-bottom: 10px;
        }

        .customer_auth__hint_container {
            width: 135%;
            display: flex;

            .customer_auth__hint_button {
                width: 28%;
                height: 56px;
                margin-left: 5%;
                background: $bolded-gray;
                border-radius: 10px;
                border: none;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: $white;
                cursor: pointer;
            }
        }
    }
}