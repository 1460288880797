.edit_profile__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .edit_profile__photo {
        width: 100%;
        height: 134px;
        position: relative;
        margin-bottom: 60px;

        input {
            display: none;
        }

        .edit_profile__profile_photo {
            width: 130px;
            height: 130px;
            object-fit: cover;
            border-radius: 65px;
            cursor: pointer;
        }

        .edit_profile__remove_photo {
            position: absolute;
            top: 90px;
            left: 95px;
            cursor: pointer;
        }
    }

    .edit_profile__data {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .edit_profile__container {
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $contacts-gray;
                margin-bottom: 5px;
            }

            input {
                width: 100%;
                height: 56px;
                background: $white;
                border: 1px solid $black;
                box-sizing: border-box;
                border-radius: 10px;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: $black;
                padding-left: 20px;
                margin-bottom: 20px;
            }

            input:focus {
                outline: none;
            }

            select {
                width: 100%;
                height: 56px;
                background: $white;
                border: 1px solid $black;
                box-sizing: border-box;
                border-radius: 10px;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: $black;
                padding-left: 20px;
                margin-bottom: 80px;
            }

            select:focus {
                outline: none;
            }

            button {
                width: 100%;
                height: 56px;
                background: $select-gray;
                border-radius: 10px;
                border: none;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: $black;
                cursor: pointer;
            }

            .edit_profile__save_button {
                background: $black;
                color: $white;
            }
        }

        .edit_profile__divider {
            width: 30%;
        }
    }
}