.reset_password__wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;

    .reset_password__logo {
        width: 55vw;
        height: 100%;
        display: flex;
        align-items: flex-end;
        background: $gray;

        img {
            margin-bottom: 5%;
            margin-left: 5%;
        }
    }

    .reset_password__form_container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .reset_password__form {
            width: 20vw;
            display: flex;
            flex-direction: column;
            margin-left: 13vw;

            p {
                font-weight: 400;
                font-size: 30px;
                line-height: 29px;
                color: $black;
            }

            input {
                height: 56px;
                border: 1px solid $black;
                box-sizing: border-box;
                border-radius: 20px;
                font-weight: 400;
                font-size: 20px;
                line-height: 23px;
                color: $black;
                padding-left: 20px;
                margin-top: 20px;
            }

            input::placeholder {
                color: $gray;
            }

            input:focus {
                outline: none;
            }

            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                padding-left: 10px;
                margin-top: 5px;
            }

            .reset_password__hint {
                color: $gray;
            }

            .reset_password__error {
                color: $occupied-red;
            }

            .reset_password__match_error {
                position: absolute;
                top: 60.5%
            }

            button {
                height: 56px;
                background: $black;
                border: none;
                border-radius: 20px;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: $white;
                margin-top: 40px;
                position: relative;
                cursor: pointer;
            }
        }
    }

}