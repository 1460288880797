.add_minute__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    position: relative;

    .add_minutes__wrapper {
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        .add_minutes__minutes_added {
            width: 30%;
            display: flex;
            margin-left: 4%;

            span {
                font-weight: 500;
                font-size: 20px;
                line-height: 23px;
                color: $minute-green;
            }
        }

        .add_minutes__divider {
            width: 30%;
        }

        .add_minutes__content {
            width: 35%;
            height: 420px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
        
            .add_minutes__selected {
                width: 100%;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background: $minute-gray;
                border-radius: 14px;
                padding-left: 20px;
                margin-top: 20px;
                cursor: pointer;
        
                span {
                    font-weight: 400;
                    font-size: 19px;
                    line-height: 22px;
                    color: $bolded-gray;
                }
        
                img {
                    width: 15px;
                    height: 8px;
                    margin-left: auto;
                    padding-right: 24px;
                }
            }
        
            .add_minutes__dropdown {
                width: 100%;
                min-height: 185px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                background: $select-gray;
                border-radius: 14px;
                padding-left: 20px;
                cursor: pointer;
                position: absolute;
                top: 20px;
                z-index: 2;
        
                .add_minutes__dropdown__selected_type {
                    width: 100%;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    border-radius: 14px;
        
                    span {
                        font-weight: 400;
                        font-size: 19px;
                        line-height: 22px;
                        color: $bolded-gray;
                    }
            
                    img {
                        width: 15px;
                        height: 8px;
                        margin-left: auto;
                        padding-right: 24px;
                    }
                }
        
                .add_minutes__dropdown_divider {
                    width: 96%;
                    border: 1px solid $minute-gray;
                }
        
                .add_minutes__dropdown_item {
                    width: 100%;
                    height: 48px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    background: $select-gray;
                    border-radius: 14px;
                    margin-left: -10px;
                    margin-top: 10px;
        
                    span {
                        font-weight: 400;
                        font-size: 19px;
                        line-height: 22px;
                        color: $bolded-gray;
                        padding-left: 13px;
                    }
                }
        
                .add_minutes__dropdown_item:hover {
                    background: $minute-gray;
                }
            }
        
            .add_minutes__dropdown__minutes {
                width: 100%;
                min-height: 185px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                background: $select-gray;
                border-radius: 14px;
                padding-left: 20px;
                cursor: pointer;
                position: absolute;
                top: 96px;
                z-index: 2;
        
                .add_minutes__dropdown__selected_minutes {
                    width: 100%;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    border-radius: 14px;
        
                    span {
                        font-weight: 400;
                        font-size: 19px;
                        line-height: 22px;
                        color: $bolded-gray;
                    }
            
                    img {
                        width: 15px;
                        height: 8px;
                        margin-left: auto;
                        padding-right: 24px;
                    }
                }
        
                .add_minutes__dropdown_divider {
                    width: 96%;
                    border: 1px solid $minute-gray;
                }
        
                .add_minutes__dropdown_item {
                    width: 100%;
                    height: 48px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    background: $select-gray;
                    border-radius: 14px;
                    margin-left: -10px;
                    margin-top: 10px;
        
                    span {
                        font-weight: 400;
                        font-size: 19px;
                        line-height: 22px;
                        color: $bolded-gray;
                        padding-left: 13px;
                    }
                }
        
                .add_minutes__dropdown_item:last-of-type {
                    margin-bottom: 10px;
                }
        
                .add_minutes__dropdown_item:hover {
                    background: $minute-gray;
                }
            }
        
            select {
                width: 100%;
                height: 56px;
                margin-top: 20px;
                background: $minute-gray;
                border-radius: 14px;
                padding-left: 20px;
                font-weight: 400;
                font-size: 19px;
                line-height: 22px;
                color: $bolded-gray;
            }
        
            select:focus {
                outline: none;
            }
        
            .add_minutes__total_container {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-bottom: 1px solid $select-gray;
                margin-top: 30px;
        
                p {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28px;
                    color: $dark-gray;
                    margin-top: 0;
                    margin-bottom: 10px;
                }
            
                b {
                    color: $black;
                }
            }
        
            button {
                width: 100%;
                height: 56px;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: $white;
                background: $black;
                border-radius: 10px;
                border: none;
                margin-top: 40px;
                cursor: pointer;
            }
        
            button:disabled {
                background: $select-gray;
            }
        }
    }
    
    .add_minutes__payment_wrapper {
        width: 82%;
        min-height: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .add_minutes__payment_info {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
    
            .add_minutes__payment_info__back {
                margin-top: 30px;
    
                img {
                    width: 12px;
                    height: 23px;
                    cursor: pointer;
                }
            }
    
            .add_minutes__payment_info__data {
                width: 40%;
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid $select-gray;
                margin-bottom: 30px;
    
                h2 {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 28px;
                    color: $bolded-gray;
                    margin-bottom: 30px;
                }
    
                span {
                    font-weight: 400;
                    font-size: 19px;
                    line-height: 22px;
                    color: $bolded-gray;
                    margin-bottom: 20px;
                }
    
                p {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28px;
                    color: $dark-gray;
                    margin-top: 10px;
                }
    
                b {
                    color: $black
                }
            }
        }
    
        .add_minutes__payment_cards {
            width: 100%;
            height: 230px;
            display: flex;
            align-items: center;
            // justify-content: flex-start;
            overflow-x: auto;
    
            .add_minutes__payment__add_card {
                min-width: 315px;
                height: 200px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: rgba(18, 18, 29, 0.05);
                border-radius: 16px;
                cursor: pointer;
                margin: auto;
            }
    
            .add_minutes__payment__card {
                min-width: 315px;
                height: 200px;
                display: flex;
                justify-content: space-between;
                background: url("../../../scss/assets/card_background.svg");
                border-radius: 16px;
                box-shadow: 0px 8px 12px rgba(183, 183, 183, 0.12);
                margin: auto;
                margin-right: 40px;
    
                .add_minutes__payment__card_info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-top: 30px;
                    margin-left: 30px;
                    margin-bottom: 30px;
                    margin-right: 20px;
    
                    img {
                        max-width: 94px;
                        height: 30px;
                        margin-bottom: 20px;
                    }
    
                    p {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.08em;
                        color: $dark;
                    }
    
                    .add_minutes__payment__holder_info__container {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
    
                        .add_minutes__payment__holder_info {
                            display: flex;
                            flex-direction: column;
    
                            span {
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                                color: $blue-gray;
                                margin-bottom: 8px;
                            }
                        }
                    }
                }
    
                button {
                    width: 70px;
                    height: 30px;
                    border-radius: 20px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    margin-top: 30px;
                    margin-right: 20px;
                }
            }
    
            .add_minutes__payment__card_selected {
                border: 1px solid $black;
            }
        }
    
        button {
            width: 35%;
            height: 56px;
            background: $black;
            border: none;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $white;
            margin-top: 30px;
            margin-bottom: 30px;
            cursor: pointer;
        }
    }
}

