.logged_out__wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;

    .logged_out__logo {
        width: 55vw;
        height: 100%;
        display: flex;
        align-items: flex-end;
        background: $gray;

        img {
            margin-bottom: 5%;
            margin-left: 5%;
        }
    }

    .logged_out__text {
        width: 45vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;

        p {
            font-weight: 400;
            font-size: 57px;
            line-height: 60px;
            color: $black;
        }
    }
}