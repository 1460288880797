.loader__modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    outline: none;

    .loader__wrapper {
        width: 100px;
        height: 100px;

        img {
            width: 100%;
            height: 100%;
        }
    }

}