// Components
@import "./components/AnalyticComponent/AnalyticsComponent.scss";

.analytics__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 30px;

    .analytics__container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
    }

    .analytics__search__container {
        width: 50%;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 20px;

        img {
            width: 18px;
            height: 18px;
            position: absolute;
            left: 20px;
        }

        input {
            width: 60%;
            height: 40px;
            background: $white;
            border: none;
            border-radius: 20px;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            padding-left: 50px;
            margin-right: 12px;
        }

        input::placeholder {
            color: $gray;
        }

        input:focus {
            outline: none;
        }

        select {
            width: 40%;
            height: 40px;
            background: $white;
            border: none;
            border-radius: 20px;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $bolded-gray;
            padding-left: 10px;
        }

        select:focus {
            outline: none;
        }
    }

    .analytics__timeframes__container {
        width: 100%;
        display: flex;
        align-items: center;

        .analytics__timeframe__container {
            width: 15%;
            height: 50px;
            display: flex;
            align-items: center;
            background: $white;
            border-radius: 60px;
            margin-right: 10px;

            .analytics__timeframe {
                display: flex;
                justify-content: center;
                align-items: baseline;
                position: relative;
                margin-left: 6px;
                padding-left: 52px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                p {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 21px;
                    color: $black;
                    margin: 0;
                }

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                }

                input:checked ~ .analytics__timeframe_checkmark {
                    background-color: $white;
                }

                input:checked ~ .analytics__timeframe_checkmark::after {
                    display: block;
                }

                .analytics__timeframe_checkmark {
                    position: absolute;
                    top: -10px;
                    left: 0;
                    height: 38px;
                    width: 38px;
                    background-color: $white;
                    border: 1px solid $radio-green;
                    border-radius: 50%;
                }

                .analytics__timeframe_checkmark::after {
                    content: "";
                    position: absolute;
                    display: none;
                    top: 4px;
                    left: 4px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: $radio-green;
                }
            }
        }

        .analytic__custom_timeframe__container {
            width: 16.5%;
            height: 50px;
            display: flex;
            align-items: center;
            background: $white;
            border-radius: 60px;
            position: relative;
            margin-right: 10px;

            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: $black;
                margin-left: 20px;
            }

            img {
                width: 14px;
                height: 7px;
                margin-left: auto;
                margin-right: 20px;
            }
            
            .react-datepicker-wrapper {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;

                .react-datepicker__input-container {
                    width: 100%;
                    height: 100%;

                    input {
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    hr {
        width: 95%;
        border: 1px solid $select-gray;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 0;
        margin-right: 0;
    }
}