.incoming_call__modal {
    width: 25%;
    height: 35%;
    position: fixed;
    top: 25%;
    left: 40%;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;

    .incoming_call__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: $dark-blue;
            margin-top: 30px;
            margin-bottom: 0px;
        }

        p:last-of-type {
            margin-top: 5px;
        }

        .incoming_call__caller_info {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: $dark-blue;
                margin-top: 5px;
                margin-bottom: 0px;
            }
        }

        img {
            width: 150px;
            height: 150px;
            margin-top: 8%;
            cursor: pointer;
        }
    }
}