.autocomplete__wrapper {
    min-width: 48%;
    max-width: 100%;
    height: 56px;
    display: flex;
    flex-direction: column;
    // position: relative;

    .autocomplete__search {
        width: 100%;
        height: 100%;
        position: relative;
        // width: 100%;
        // height: 100%;
        // display: flex;
        // align-items: center;
        // position: relative;
        // border: 1px solid $black;
        // box-sizing: border-box;
        // border-radius: 14px;

        img {
            width: 18px;
            height: 18px;
            position: absolute;
            top: 18px;
            z-index: 4;
            margin-left: 10px;
        }
        
        input {
            width: 90%;
            height: 56px;
            position: absolute;
            border: none;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            background: transparent;
            color: $black;
            padding-top: 0;
            padding-bottom: 0;
            margin-left: 40px;
            z-index: 4;
        }
    
        input::placeholder {
            color: $gray;
            z-index: 4;
        }
    
        input:focus {
            outline: none;
        }
    }

    .autocomplete__suggestions_container {
        width: 100.3%;
        max-height: 270px;
        display: flex;
        flex-direction: column;
        position: absolute;
        // top: -1px;
        left: -1px;
        z-index: 0;
        background: $white;
        border: 1px solid $black;
        box-sizing: border-box;
        // border-radius: 14px;
        padding-top: 56px;
        border-top: none;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;

        hr {
            width: 93%;
            border-top: 1px solid $black;
            margin-top: -5px;
            margin-bottom: 0;
        }

        .autocomplete__suggestions_wrapper {
            overflow-y: scroll;

            .autocomplete__suggestion {
                height: 35px;
                display: flex;
                align-items: center;
                padding-left: 3.5%;
                padding-right: 2.5%;
                margin-bottom: 15px;
                margin-top: 7px;
                cursor: pointer;

                img {
                    width: 35px;
                    height: 35px;
                    margin-right: 10px;
                }

                .autocomplete__suggestion_name {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    background: $background-gray;
                    border-radius: 20px;
                    
                    p {
                        padding-left: 10px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: $black;
                    }
                }

                .autocomplete__concierge_container {
                    width: 33%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: $background-gray;
                    border-radius: 20px;
                    padding-left: 10px;
                    padding-right: 3px;

                    p {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: $black;
                    }

                    .autocomplete__concierge_role {
                        width: 100px;
                        height: 29px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 0px 2px 6px rgba(47, 83, 151, 0.1);
                        border-radius: 20px;

                        span {
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 29px;
                            color: $white;
                        }
                    }

                    .autocomplete__concierge_role_0 {
                        background: $blue;
                    }

                    .autocomplete__concierge_role_1 {
                        background: $status-green;
                    }

                    .autocomplete__concierge_role_2 {
                        background: $purple;
                    }
                }
            }
        }

    }
}