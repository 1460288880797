.user_card__container__available {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background: rgba($color: $available-green, $alpha: 0.3);
    border-radius: 14px;
    margin-bottom: 20px;
}

.user_card__container__occupied {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background: rgba($color: $occupied-red, $alpha: 0.3);
    border-radius: 14px;
    margin-bottom: 20px;
}

.user_card__container__offline {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: $select-gray;
    border-radius: 14px;
    margin-bottom: 20px;
}

.user_card__picture {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 6px;

    img {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 24px;
    }
}

.user_card__details {
    display: flex;
    flex-direction: column;
    padding-left: 3%;
    max-height: 60px;

    .user_card__name {
        margin: 0;
        height: 24px;
        color: $black;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        margin-top: 6px;
        margin-bottom: 2px;
    }

    .user_card__email {
        margin: 0;
        height: 23px;
        color: $bolded-gray;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }
}

.user_card__role {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 100px;
    height: 30px;
    box-shadow: 0px 2px 6px rgba(47, 83, 151, 0.1);
    border-radius: 20px;
    margin-right: 20px;

    p {
        color: $white;
        font-weight: 700;
        font-size: 12px;
        line-height: 29px;
    }
}

.user_card__menu {
    padding-right: 20px;
    position: relative;
    cursor: pointer;
}

.Admin_role {
    background-color: $purple;
}

.Manager_role {
    background-color: $status-green;
}

.Concierge_role {
    background-color: $blue;
}