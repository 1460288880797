.in_call__modal {
    width: 90%;
    min-height: 35%;
    position: fixed;
    top: 6%;
    left: 5%;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;

    .in_call__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .in_call__top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            
            img {
                width: 68px;
                height: 68px;
                margin-left: 2%;
                margin-right: 2%;
                cursor: pointer;
            }

            .in_call__note_buttons {
                width: 25%;
                display: flex;
                justify-content: space-around;
                margin-left: -5%;

                p {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 23px;
                    text-decoration-line: underline;
                    color: $dark-green;
                    cursor: pointer;
                }
            }

            .in_call__divider {
                width: 20%;
            }
    
            .in_call__customer_info {
                display: flex;
                flex-direction: column;
                align-items: center;

                p {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    color: $dark-blue;
                    margin-top: 30px;
                    margin-bottom: 10px;
                }
    
            }
        }
        
        .in_call__actions {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img:last-of-type {
                margin-right: 3%;
            }

            img {
                cursor: pointer;
            }

            .in_call__boost_plan__dropdown__container {
                width: 232px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 3%;
                position: relative;

                img {
                    margin-left: 0;
                    margin-right: 0;
                }

                .in_call__boost_plan__dropdown {
                    width: 232px;
                    width: 100%;
                    min-height: 56px;
                    max-height: 124px;
                    background-color: $dark-green;
                    border: 1px solid $dark-green;
                    box-sizing: border-box;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: absolute;
                    top: -27px;
                    left: 0px;
                    z-index: 2;
                    
                    .in_call__boost_plan__dropdown_item {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                    }

                    .in_call__boost_plan__dropdown_divider {
                        width: 90%;
                        border: 1px solid $white;
                    }
                }
            }

        }

        .in_call__action_content {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}