.boostplan__wrapper {
    width: 100%;
    height: 630px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .boostplan__container {
        width: 25%;
        height: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $subscription-gray;
        border-radius: 24px;

        h2 {
            font-weight: 400;
            font-size: 52px;
            line-height: 61px;
            margin-top: 16px;
            margin-bottom: 16px;
            color: $black;
        }

        .boostplan__points_container {
            width: 89%;
            margin-bottom: auto;
            text-align: center;
            
            p {
                font-weight: 400;
                font-size: 17px;
                line-height: 100%;
                margin-top: 0px;
                margin-bottom: 10px;
            }
        }

        .boostplan__radio_group {
            width: 90%;
            height: 50px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: $white;
            border-radius: 60px;
            margin-bottom: 10px;

            .boostplan__radio_container {
                display: flex;
                justify-content: center;
                align-items: baseline;
                position: relative;
                margin-left: 20px;
                padding-left: 35px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                p {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28px;
                    color: $black;
                    margin: 0;
                }

                .boostplan__discount {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 100%;
                    color: $status-green;
                    margin-left: 10px;
                }

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                }

                input:checked ~ .boostplan__radio_checkmark {
                    background-color: $black;
                }

                input:checked ~ .boostplan__radio_checkmark::after {
                    display: block;
                }

                .boostplan__radio_checkmark {
                    position: absolute;
                    top: 2px;
                    left: 0;
                    height: 25px;
                    width: 25px;
                    background-color: $white;
                    border: 1px solid $black;
                    border-radius: 50%;
                }

                .boostplan__radio_checkmark::after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 9px;
                    top: 5px;
                    width: 5px;
                    height: 10px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }

        .boostplan__radio_group__disabled {
            opacity: 0.5;
        }

        button {
            width: 90%;
            height: 56px;
            background: $black;
            border: none;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $white;
            cursor: pointer;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .boostplan__divider {
            width: 90%;
            height: 56px;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }

    .boostplan__container__selected {
        border: 2px solid $black;
    }
}