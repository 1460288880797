.reassign_ticket__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .reassign_ticket__top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        margin-bottom: 30px;

        .reassign_ticket__customer_info {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-left: 2%;

            p {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: $dark-blue;
                margin-top: 0;
                margin-bottom: 10px;
            }

            span {
                font-weight: 400;
                font-size: 18px;
                line-height: 29px;
                color: $black;
            }
        }

        .reassign_ticket__call_info {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                font-weight: 400;
                font-size: 18px;
                line-height: 29px;
                color: $black;
            }

            p {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: $black;
                margin-top: 5px;
                margin-bottom: 10px;
                margin-left: 0;
                margin-right: 0;
            }
        }

        .reassign_ticket__button {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-right: 2%;

            img {
                width: 68px;
                height: 68px;
                cursor: pointer;
            }
        }

    }

    .reassign_ticket__content {
        width: 37vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        .reassign_ticket__input_container {
            min-width: 100px;
            width: 100%;
            min-height: 57px;
            height: fit-content;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            position: relative;
            border: 1px solid $black;
            box-sizing: border-box;
            border-radius: 14px;
            margin-bottom: 20px;
            padding-left: 10px;
            padding-right: 10px;
        }

        button {
            width: 100%;
            height: 56px;
            background: $reassign-orange;
            border: none;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $white;
            margin-bottom: 30px;
            cursor: pointer;
        }
    }
}