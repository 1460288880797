.creditcard__container {
    width: 30%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .creditcard__stripe__card_number {
        width: 100%;
        height: 56px;
        border: 1px solid $black;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $black;
        border-radius: 10px;
        padding-top: 12px;
        padding-left: 10px;
        margin-bottom: 10px;
    }

    p {
        font-weight: 400;
        font-size: 26px;
        line-height: 30px;
        color: $black;
        margin-bottom: 40px;
    }

    input {
        width: 100%;
        height: 56px;
        border: 1px solid $black;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $black;
        border-radius: 10px;
        padding-left: 10px;
        margin-bottom: 10px;
    }

    input:focus {
        outline: none;
    }

    input::placeholder {
        color: $gray;
    }

    .creditcard__input_container {
        width: 100%;
        display: flex;
        justify-content: center;

        .creditcard__stripe__card_exp_cvc {
            width: 48.5%;
            height: 56px;
            border: 1px solid $black;
            box-sizing: border-box;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $black;
            border-radius: 10px;
            padding-top: 12px;
            padding-left: 10px;
            margin-bottom: 10px;
        }

        .creditcard__stripe__card_exp_cvc:first-child {
            margin-right: auto;
        }

        input {
            width: 48.5%;
        }

        input:focus {
            outline: none;
        }

        input:first-child {
            margin-right: auto;
        }
    }

    .creditcard__button_container {
        width: 130%;
        display: flex;
        justify-content: center;
        margin-top: 60px;
        margin-bottom: 30px;


        button {
            width: 49%;
            height: 56px;
            border-radius: 10px;
            border: none;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            cursor: pointer;
        }

        button:first-child {
            margin-right: auto;
        }

        .creditcard__button__cancel {
            background: $select-gray;
            color: $black;
        }

        .creditcard__button__continue {
            background: $black;
            color: $white;
        }
    }

}