@import "./components/ConciergeCard/ConciergeCard.scss";
@import "./components/TicketInformationModal/TicketInformationModal.scss";

.ticket_management__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .ticket_management__header {
        width: 95%;
        display: flex;
        flex-direction: column;
        
        .ticket_management__search {
            // min-width: 100px;
            width: 100%;
            min-height: 52px;
            height: fit-content;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            position: relative;
            border: 1px solid $black;
            box-sizing: border-box;
            background: $white;
            border-radius: 14px;
            margin-bottom: 20px;
            padding-left: 10px;
            padding-right: 10px;
            z-index: 3;
        }

        .ticket_management__filters {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .ticket_management__dropdown_container {
                width: 18%;
                height: 52px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                cursor: pointer;
                margin-right: 3%;
                z-index: 1;

                .ticket_management__selected {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: $select-gray;
                    border: 1px solid $black;
                    box-sizing: border-box;
                    border-radius: 14px;
                    padding-left: 14px;
                    padding-right: 22px;
                    
                    span {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 21px;
                        color: $black;
                    }
    
                    img {
                        margin-left: auto;
                    }

                    p {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 21px;
                        color: $black;
                        position: absolute;
                    }

                    .react-datepicker-wrapper {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        z-index: 1;
        
                        .react-datepicker__input-container {
                            width: 100%;
                            height: 100%;
        
                            input {
                                width: 100%;
                                height: 100%;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .ticket_management__dropdown {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background: $white;
                    border: 1px solid $black;
                    box-sizing: border-box;
                    border-radius: 14px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    padding-left: 14px;
                    padding-right: 22px;

                    .ticket_management__dropdown__selected {
                        width: 100%;
                        height: 51px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        span {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 21px;
                            color: $black;
                        }
        
                        img {
                            margin-left: auto;
                        }
                    }

                    .ticket_management__dropdown_divider {
                        width: 100%;
                        border: 0.5px solid $select-gray;
                        margin-bottom: 10px;
                    }

                    .ticket_management__dropdown_item {
                        width: 100%;
                        height: 43px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .ticket_management__dropdown_divider {
                            width: 100%;
                            border: 0.5px solid $select-gray;
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }
                    }

                    .ticket_management__dropdown_item:last-of-type {
                        height: 33px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;

                        .ticket_management__dropdown_divider {
                            display: none;
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                    }
                }

                .ticket_management__dropdown_menu {
                    width: 100%;
                    height: 102px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: $white;
                    border: 1px solid $black;
                    box-sizing: border-box;
                    border-radius: 5px;
                    position: absolute;
                    top: 50px;
                    left: 0;

                    .ticket_management__dropdown_item {
                        width: 100%;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 22px;
                            color: $bolded-gray;
                            margin: 0;
                        }
                    }
                }
            }

            .ticket_management__dropdown_container:last-of-type {
                margin-right: 0;
            }
        }
    }

    hr {
        width: 98%;
        margin-left: 0;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .ticket_management__ticket_container {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 20px;

        .ticket_management__ticket {
            width: 343px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: $select-gray;
            border: 1px solid $black;
            box-sizing: border-box;
            border-radius: 14px;
            padding-left: 20px;
            padding-right: 10px;
            cursor: pointer;

            span {
                font-weight: 400;
                font-size: 20px;
                line-height: 23px;
                color: $black;
            }

            .ticket_management__ticket_status {
                width: 110px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $occupied-red;
                border-radius: 10px;

                span {
                    color: $white;
                }
            }

            .ticket_management__ticket_status__0 {
                background: $occupied-red;
            }

            .ticket_management__ticket_status__1 {
                background: $status-green;
            }

            .ticket_management__ticket_status__2 {
                background: $orange;
            }
            
            .ticket_management__ticket_status__3 {
                background: $orange;
            }
            
            .ticket_management__ticket_status__4 {
                background: $orange;
            }
            
            .ticket_management__ticket_status__5 {
                background: $orange;
            }
            
            .ticket_management__ticket_status__6 {
                background: $orange;
            }
        }
    }
}