.edit_employee__modal {
    width: 30%;
    height: 700px;
    position: fixed;
    top: 10%;
    left: 35%;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 20px;

    .edit_employee__wrapper {
        display: flex;
        flex-direction: column;
        padding-left: 10%;
        padding-right: 10%;

        p {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.43px;
            margin-top: 40px;
        }

        span {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $contacts-gray;
            margin-bottom: 5px;
        }

        input, select {
            width: 100%;
            height: 56px;
            background: rgba($color: $white, $alpha: 0.5);
            border: 1px solid $black;
            box-sizing: border-box;
            border-radius: 10px;
            padding-left: 5%;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $black;
            margin-bottom: 20px;
        }

        input::placeholder {
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $black;
        }

        .edit_employee__save {
            width: 100%;
            height: 56px;
            background: $black;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $white;
            border: none;
            margin-top: 30px;
            cursor: pointer;
        }

        .edit_employee__cancel {
            width: 100%;
            height: 56px;
            background: $select-gray;
            border: 1px solid $black;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $black;
            margin-top: 20px;
            cursor: pointer;
        }
    }
}