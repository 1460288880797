@import 'components//SubscriptionPlanForm/SubscriptionPlanForm.scss';

.subscription_plan__page_wrapper {
    width: 100%;
    
    .subscription_plan__wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        margin-bottom: 20px;
    
        .subscription_plan__container {
            width: 28%;
            height: 710px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: $subscription-gray;
            border-radius: 24px;
            border: 1px solid $black;
    
            h2 {
                font-weight: 400;
                font-size: 52px;
                line-height: 61px;
                margin-top: 16px;
                margin-bottom: 16px;
                color: $black;
            }
    
            .subscription_plan__points_container {
                width: 89%;
                margin-bottom: auto;
                text-align: center;
    
                p {
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 100%;
                    margin-top: 0px;
                    margin-bottom: 10px;
                }
            }
    
            .subscription_plan__price_container {
                width: 90%;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $white;
                border-radius: 60px;
                margin-bottom: 10px;
    
                p {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28px;
                    color: $black;
                    margin: 0;
                }
            }
    
            a {
                width: 90%;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $black;
                border: none;
                border-radius: 10px;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: $white;
                margin-top: 40px;
                margin-bottom: 20px;
                text-decoration: inherit;
                cursor: pointer;
            }
            
            button {
                width: 90%;
                height: 56px;
                background: $black;
                border: none;
                border-radius: 10px;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: $white;
                margin-bottom: 20px;
                cursor: pointer;
            }
        }

        .subscription_plan__container__inactive {
            opacity: 0.5;
        }
    
        .subscription_plan__new_plan_container {
            width: 28%;
            height: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: $subscription-gray;
            border-radius: 24px;
            border: 1px solid $black;
            margin-bottom: 20px;
    
            h2 {
                font-weight: 400;
                font-size: 52px;
                line-height: 61px;
                margin-top: 16px;
                color: $black;
            }
    
            a {
                width: 90%;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $black;
                border: none;
                border-radius: 10px;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: $white;
                margin-top: 40px;
                margin-bottom: 20px;
                text-decoration: inherit;
                cursor: pointer;
            }
        }
    }
}