.contact_number_selection__modal {
    width: 35vw;
    min-height: 300px;
    position: fixed;
    top: 25%;
    left: 32.5vw;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;

    .customer_number_selection__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font-weight: 700;
            font-size: 40px;
            line-height: 47px;
            color: $black;
        }

        .customer_number_selection__number_container {
            width: 100%;
            display: flex;
            flex-direction: column;

            .customer_number_selection__number {
                width: 65%;
                display: flex;
                align-items: center;
                padding-left: 25%;

                input {
                    transform: scale(2);
                    margin-right: 20px;
                }

                label {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    color: $black;
                }
            }
        }

        button {
            width: 70%;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $black;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $white;
            border: none;
            cursor: pointer;
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
}