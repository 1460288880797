.voice_message__wrapper {
    width: 37vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;

    .voice_message__search_container {
        min-width: 100px;
        width: 100%;
        min-height: 57px;
        height: fit-content;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        position: relative;
        border: 1px solid $black;
        box-sizing: border-box;
        border-radius: 14px;
        margin-bottom: 40px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .voice_message__audio_container {
        width: 85%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 60px;

        .voice_message__play {
            width: 55px;
            height: 55px;
            cursor: pointer;
        }

        .voice_message__stop {
            width: 55px;
            height: 55px;
            // cursor: pointer;
        }

        .voice_message__close {
            width: 55px;
            height: 55px;
            cursor: pointer;
        }
    }

    button {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $black;
        border-radius: 10px;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $white;
        border: none;
        cursor: pointer;

        img {
            margin-right: 14px;
        }
    }

    button:disabled {
        background: $gray;
        cursor: default;
    }
}