.user_card__menu__dropdown {
    width: 100px;
    min-height: 30px;
    max-height: 60px;
    background-color: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 2;
    
    .user_card__menu__dropdown_item {
        width: 98px;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        img {
            width: 16px;
            height: 16px;
            padding-left: 10px;
            margin-right: auto;
        }
        
        p {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: auto;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            display: flex;
            align-items: center;
            text-align: center;
            color: $bolded-gray;
        }
    }

    .user_card__menu__dropdown_item_border {
        border-bottom: 1px solid $black;
    }
    
    // .user_card__menu__dropdown_item {
    //     width: 98px;
    //     height: 30px;
    //     display: flex;
    //     justify-content: space-evenly;
    //     align-items: center;
        
    //     img {
    //         width: 16px;
    //         height: 16px;
    //         padding-left: 10px;
    //         margin-right: auto;
    //     }
        
    //     p {
    //         margin-top: 0;
    //         margin-bottom: 0;
    //         margin-right: auto;
    //         font-size: 12px;
    //         font-weight: 500;
    //         line-height: 15px;
    //         display: flex;
    //         align-items: center;
    //         text-align: center;
    //         color: $bolded-gray;
    //     }
    // }
}