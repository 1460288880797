.contact__info__label__wrapper {
    width: 100%;
    height: 68px;
    background-color: #eeeeee;
    border-radius: 14px;
    margin-bottom: 10px;
    padding-bottom: 10px;
  
    .contact__info__label__inner_wrapper {
        padding-left: 20px;
        padding-top: 10px;
    
        .label__name__text {
            color: rgba(130, 130, 130, 1);
            font-size: 16px;
            text-align: left;
            margin: 0;
        }
    
        .value_text {
            margin-top: 7px;
            font-size: 20px;
            text-align: left;
        }
    }
}
  