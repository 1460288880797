.analytic_component__wrapper {
    width: 32vw;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    background: $white;
    box-shadow: 0px 8px 12px rgba(183, 183, 183, 0.12);
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 24px;

    .analytic_component__header {
        display: flex;
        align-items: center;
        padding-left: 5%;
        padding-right: 5%;
        margin-bottom: 30px;

        h2 {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: $black;
            margin-right: 10px;
        }

        .analytic_component__info {
            display: flex;
            align-items: center;
            margin-right: auto;
            position: relative;

            img {
                width: 18px;
                height: 18px;
            }

            .analytic_component__info_text {
                width: 250px;
                background: $black;
                border-radius: 4px;
                position: absolute;
                top: 70%;
                left: -140px;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: $white;
                padding: 14px 20px;
                z-index: 3;
            }

            .analytic_component__info_text::before {
                content: '';
                position: absolute;
                top: -5%;
                left: 48%;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid $black;
                clear: both;
            }
        }

        .analytic_component__timeframe {
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;

            select {
                height: 22px;
                border: none;
                background: transparent;
                box-shadow: none;
                border-radius: 8px;
                cursor: pointer;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: $black;
            }

            .analytic_component__selected_select {
                background: $select-gray;
                box-shadow: 0px 8px 12px rgba(183, 183, 183, 0.12);
            }

            select:focus {
                outline: none;
            }

            // span {
            //     font-weight: 400;
            //     font-size: 12px;
            //     line-height: 16px;
            //     color: $black;
            //     margin-right: 5px;
            //     cursor: pointer;
            // }
    
            // .analytic_component__arrow {
            //     width: 10px;
            //     height: 10px;
            //     cursor: pointer;
            // }

            // .analytic_component__timeframe_selection {
            //     display: flex;
            //     align-items: center;
            //     position: absolute;
            //     right: 0%;
            //     opacity: 0;
            //     cursor: pointer;

            //     select {
            //         cursor: pointer;
            //     }
            // }
        }
    }

    .analytic_component__content {
        display: flex;
        padding-left: 5%;
        padding-right: 5%;

        .analytics_component__today {
            width: 32%;
            height: 40px;
            display: flex;
            align-items: center;
            background: $black;
            border-radius: 20px;

            p {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: $white;
                padding-left: 15%;
            }
        }

        .analytic_component__interval_container {
            width: 100%;
            height: 100%;

            .analytic_component__interval {
                min-width: 100%;
                height: 150px;
    
                canvas {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .analytic_component__search {
            width: 90%;
            height: 40px;
            display: flex;
            align-items: center;
            background: $analytic-search-gray;
            border-radius: 20px;
            margin-bottom: 30px;

            img {
                width: 18px;
                height: 18px;
                margin-left: 24px;
            }

            input {
                width: 100%;
                height: 100%;
                background: transparent;
                border: none;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $black;
                margin-left: 15px;
            }

            input::placeholder {
                color: $gray;
            }

            input:focus {
                outline: none;
            }
        }
    }
}