.create_employees__wrapper {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 2%;
    padding-top: 5%;

    input, select {
        width: 100%;
        height: 56px;
        background: rgba($color: $white, $alpha: 0.5);
        border: 1px solid $black;
        box-sizing: border-box;
        border-radius: 10px;
        padding-left: 5%;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $black;
    }

    input::placeholder {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $black;
    }

    .create_employees__names {
        display: flex;
        flex-direction: column;

        input {
            margin-bottom: 20px;
        }
    }
    
    .create_employees__email {
        margin-top: 30px;
    }

    .create_employees__role {
        margin-top: 64px;
    }

    .create_employees__button {
        width: 100%;
        height: 56px;
        background: $black;
        border-radius: 10px;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $white;
        border: none;
        margin-top: 64px;
        cursor: pointer;
    }
    
    .create_employees__button[disabled] {
        background: #ccc;
        cursor: default;
    }
}