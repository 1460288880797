.creditcard_needed__modal {
    width: 45%;
    height: 250px;
    position: fixed;
    top: 14%;
    left: 27.5%;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;

    .creditcard_needed__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .creditcard_needed__top {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: $black;
                margin-top: 40px;
                margin-bottom: 0px;
            }

            img {
                width: 40px;
                height: 40px;
                cursor: pointer;
                position: absolute;
                top: 20px;
                right: 20px;
            }
        }

        button {
            width: 40%;
            height: 56px;
            background: $black;
            border: none;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $white;
            cursor: pointer;
            margin-top: 80px;
        }
    }
}