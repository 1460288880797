$black: #000000;
$dark: #2B2E30;
$white: #FFFFFF;

$background-gray: #EEEEEE;
$select-gray: #E0E0E0;
$subscription-gray: #E3E3E3;
$minute-gray: #F5F3F5;
$light-gray: #F5F7FA;
$analytic-search-gray: #F4F4F4;
$gray: #BDBDBD;
$select-gray: #D9D9D9;
$contacts-gray: #ACACAC;
$dark-gray: #828282;
$ticket-gray : #636363;
$bolded-gray: #4F4F4F;
$blue-gray: #869AA5;

$status-green: #27AE60;
$dark-green: #219653;
$available-green: #6FCF97;
$minute-green: #00B44C;
$radio-green: #4CDA64;

$occupied-red: #EB5757;
$red: #F53A3A;

$orange: #FF8920;
$reassign-orange: #FB8832;
$ticket-orange: #F2994A;

$purple: #9B51E0;

$blue: #2F80ED;
$dark-blue: #1E2E46;