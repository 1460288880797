.ticket_summary__modal {
    width: 90%;
    min-height: 35%;
    position: fixed;
    top: 6%;
    left: 5%;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;

    .ticket_summary__wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 40px;

        .ticket_summary__green {
            color: $minute-green !important;
        }

        .ticket_summary__call_info {
            width: 20%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 40px;

            p {
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: $black;
            }

            span {
                font-weight: 500;
                font-size: 18px;
                line-height: 29px;
                color: $black;
            }
        }

        .ticket_summary__ticket_info__container {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h1 {
                font-weight: 700;
                font-size: 40px;
                line-height: 29px;
                color: $black;
                align-self: center;
                margin-top: 0px;
                margin-bottom: 150px;
            }

            hr {
                width: 100%;
                border: 1px solid $select-gray;
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .ticket_summary__ticket_info {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .ticket_summary__bottom_margin {
                    margin-bottom: 10px;
                }

                h2 {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    color: $black;
                    margin-top: 0;
                    margin-bottom: 10px;
                }

                span {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 29px;
                    color: $ticket-gray;
                }

                p {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 29px;
                    color: $ticket-gray;
                    margin-top: 0;
                    margin-bottom: 0;
                }

                textarea {
                    width: 100%;
                    height: 150px;
                    background: $white;
                    border: 1px solid $dark-gray;
                    box-sizing: border-box;
                    border-radius: 14px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 21px;
                    color: $ticket-gray;
                    font-family: Arial, Helvetica, sans-serif;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    padding-left: 15px;
                    padding-right: 60px;
                    padding-top: 15px;
                }

                textarea:focus {
                    outline: none;
                }
            }

            .ticket_summary__dropdown_container {
                width: 100%;
                margin-top: 20px;
                position: relative;

                .ticket_summary__selected {
                    width: 100%;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: $red;
                    border-radius: 20px;
                    cursor: pointer;
    
                    .ticket_summary__divider {
                        width: 30%;
                    }

                    .ticket_summary__selected_container {
                        width: 30%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 28px;
                            color: $white;
                        }
                    }
    
                    .ticket_summary__arrow_container {
                        width: 30%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
    
                        img {
                            width: 15px;
                            height: 8px;
                            padding-right: 24px;
                        }
                    }
                }

                .ticket_summary__selected__0 {
                    background: $red;
                }

                .ticket_summary__selected__1 {
                    background: $minute-green;
                }

                .ticket_summary__selected__2 {
                    background: $orange;
                }
                
                .ticket_summary__selected__3 {
                    background: $orange;
                }
                
                .ticket_summary__selected__4 {
                    background: $orange;
                }
                
                .ticket_summary__selected__5 {
                    background: $orange;
                }
                
                .ticket_summary__selected__6 {
                    background: $orange;
                }
            
                .ticket_summary__dropdown {
                    width: 100%;
                    min-height: 400px;
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: center;
                    background: $dark-gray;
                    border-radius: 14px;
                    cursor: pointer;
                    position: absolute;
                    bottom: 0;
                    z-index: 2;
            
                    .ticket_summary__dropdown__selected_type {
                        width: 100%;
                        height: 56px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-radius: 20px;
            
                        .ticket_summary__divider {
                            width: 30%;
                        }
                
                        .ticket_summary__selected_container {
                            width: 30%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
    
                            span {
                                font-weight: 400;
                                font-size: 24px;
                                line-height: 28px;
                                color: $white;
                            }
                        }
        
                        .ticket_summary__arrow_container {
                            width: 30%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
        
                            img {
                                width: 15px;
                                height: 8px;
                                padding-right: 24px;
                            }
                        }
                    }
            
                    .ticket_summary__dropdown_divider {
                        width: 80%;
                        border: 0.5px solid $select-gray;
                        margin-top: 20px;
                        margin-bottom: 10px;
                    }
            
                    .ticket_summary__dropdown_item {
                        width: 100%;
                        max-height: 68px;
                        display: flex;
                        flex-direction: column-reverse;
                        justify-content: center;
                        align-items: center;
                        border-radius: 14px;
            
                        span {
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 28px;
                            color: $white;
                        }

                        .ticket_summary__dropdown_divider {
                            width: 80%;
                            border: 1px solid $select-gray;
                            margin-top: 20px;
                            margin-bottom: 20px;
                        }
                    }

                    .ticket_summary__dropdown_item:last-of-type {
                        margin-top: 20px;

                        .ticket_summary__dropdown_divider {
                            display: none;
                        }
                    }
                }
            }

            button {
                width: 100%;
                height: 56px;
                background: $black;
                border: none;
                border-radius: 20px;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: $white;
                cursor: pointer;
                margin-top: 20px;
                margin-bottom: 60px;
            }
        }

        .ticket_summary__divider {
            width: 20%;
        }
    }
}