// Components
@import "./components/EmployeeCard/EmployeeCard.scss";
@import "./components//DropdownMenu//DropdownMenu.scss";
@import "./components/EditEmployee/EditEmployeeModal.scss";
@import "./components/DeleteEmployee/DeleteEmployeeModal.scss";

.employees__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .search_container {
        width: 88%;
        height: 52px;
        display: flex;
        color: $dark-gray;
        margin-bottom: 71px;

        .search_container__searchbar {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            margin-right: 14px;

            input {
                width: 100%;
                height: 100%;
                font-size: 18px;
                border: 1px solid $black;
                border-radius: 14px;
                padding-left: 50px;
            }

            input:focus {
                outline: none;
            }

            img {
                width: 16px;
                height: 16px;
                position: absolute;
                left: 24px;
            }
        }

        .search_container__filter {
            max-width: 215px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;

            select {
                width: 100%;
                height: 100%;
                background-color: $select-gray;
                border: 1px solid $black;
                box-sizing: border-box;
                border-radius: 14px;
                color: $black;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                align-items: center;
                padding-left: 10px;
                appearance: none;
                -webkit-appearance: none;
            }

            select:focus {
                outline: none;
            }

            img {
                width: 16px;
                height: 16px;
                position: absolute;
                right: 20px;
            }
        }
    }

    .user_container__wrapper {
        width: 96%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .user_container__header_container {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-content: center;

            .user_container__header {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-content: center;

                p {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 29px;
                    color: $bolded-gray;
                }
            }
            
        }

        .user_container__container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-evenly;

            .user_container {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            
            .user_container__divider {
                min-height: 60vh;
                display: flex;
                align-items: center;
                border: 1px solid $contacts-gray;
                transform: rotate(90);
                margin-left: 10px;
                margin-right: 10px;
            }
        }

    }
}