.delete_employee__modal {
    width: 30%;
    height: 352px;
    position: fixed;
    top: 30%;
    left: 35%;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 20px;

    .delete_employee__wrapper {
        display: flex;
        flex-direction: column;
        padding-left: 10%;
        padding-right: 10%;

        p {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.43px;
            margin-top: 40px;
        }

        span {
            font-weight: 500;
            font-size: 19px;
            line-height: 29px;
            display: flex;
            align-items: center;
            letter-spacing: 0.43px;
            margin-bottom: 40px;
        }

        .delete_employee__delete {
            width: 100%;
            height: 56px;
            background: $black;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $white;
            border: none;
            cursor: pointer;
        }

        .delete_employee__cancel {
            width: 100%;
            height: 56px;
            background: $select-gray;
            border: 1px solid $black;
            border-radius: 10px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: $black;
            margin-top: 20px;
            cursor: pointer;
        }
    }
}