.contact_modal__modal {
    width: 26%;
    min-height: 90vh;
    position: fixed;
    top: 3.33%;
    left: 34%;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 24px;
    outline: none;

    .contact_modal__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .card_modal__header {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        .card_modal__header_divider {
            width: 30%;
            height: 0px;
        }
    
        .card_modal__profile__picture {
            width: 30%;
            height: 80px;
            // border-radius: 40px;
            // background-color: $contacts-gray;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;

            img {
                width: 80px;
                height: 80px;
                border-radius: 40px;
                object-fit: cover;
            }
        
            // .card_modal__profile__picture__initials {
            //     color: rgba(254, 254, 255, 1);
            //     font-size: 30px;
            // }
        }

        .contact_modal__close {
            width: 30%;
            display: flex;
            justify-content: flex-end;

            img {
                width: 35px;
                height: 35px;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
    
        .contact_modal__fullname_text {
            margin-top: 10px;
            align-self: center;
            color: rgba(0, 0, 0, 1);
            font-size: 30px;
        }
    
        .contact_modal__innner_wrapper {
            overflow-y: scroll;
            flex: 1;
            margin: 20px 25px 10px 40px;
            padding-right: 15px;

            .contact_modal__divider {
                height: 0px;
                margin-top: 42px;
            }
        }
    
        .buttons__wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            margin-left: 40px;
            margin-right: 40px;
        
            .divider {
                height: 10px;
            }
        }
}