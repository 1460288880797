.page_wrapper {
    width: 100vw;
    min-height: 100vh;
    background-color: $background-gray;
    display: flex;

    .page_wrapper__content {
        width: 100%;
        height: 100%;
        margin-left: 16.5vw;

        .page_wrapper__content--wrapper {
            width: 100%;
            height: 84%;
        }
    }
}