.concierge_card__wrapper {
    width: 500px;
    height: 180px;
    display: flex;
    align-items: center;
    background: rgba($color: $available-green, $alpha: 0.3);
    border-radius: 14px;
    margin-top: 30px;
    margin-bottom: 50px;

    .concierge_card__picture {
        width: 160px;
        height: 160px;
        margin-left: 10px;
        margin-right: 20px;
        
        img {
            width: 100%;
            height: 100%;
            border-radius: 80px;
        }
    }

    .concierge_card__content {
        width: 340px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .concierge_card__name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            margin-bottom: 20px;

            p {
                font-weight: 400;
                font-size: 26px;
                line-height: 30px;
                color: $black;
                margin: 0;
            }

            .concierge_card__role {
                width: 100px;
                height: 30px;
                display: flex;
                justify-content: center;
                text-align: center;
                box-shadow: 0px 2px 6px rgba(47, 83, 151, 0.1);
                border-radius: 20px;
                margin-right: 10px;

                span {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 29px;
                    color: $white;
                }
            }

            .Admin_role {
                background-color: $purple;
            }
            
            .Manager_role {
                background-color: $status-green;
            }
            
            .Concierge_role {
                background-color: $blue;
            }
        }
        
        .concierge_card__data {
            height: 30px;
            margin-bottom: 10px;
    
            span {
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                color: $bolded-gray;

                b {
                    font-weight: 500;
                    color: $black;
                }
            }
        }
    }
}