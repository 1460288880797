.contact_listing__wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    .contact_listing__search_container {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;

        .contact_listing__search {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            input {
                width: 100%;
                height: 100%;
                font-size: 18px;
                border: 1px solid $black;
                box-sizing: border-box;
                border-radius: 14px;
                padding-left: 50px;
                color: $black;
            }

            input::placeholder {
                color: $gray;
            }

            input:focus {
                outline: none;
            }

            img {
                width: 16px;
                height: 16px;
                position: absolute;
                left: 24px;
            }
        }
    }

    .contact_listing__contact_list_containers {
        width: 100%;
        height: 371px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 54px;

        .contact_listing__contact_list_container {
            width: 45%;
            height: 100%;
            display: flex;
            flex-direction: column;
            border: 1px solid $black;
            box-sizing: border-box;
            border-radius: 14px;
            padding-right: 10px;
            padding-bottom: 10px;

            ::-webkit-scrollbar {
                width: 6px;
            }
            
            ::-webkit-scrollbar-track {
                display: none;
            }
            
            ::-webkit-scrollbar-thumb {
                border: 4px solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
                background: $contacts-gray; 
                border-radius: 20px;
            }

            h2 {
                font-weight: 700;
                font-size: 18px;
                line-height: 21px;
                color: $black;
                margin-top: 15px;
                margin-bottom: 25px;
                margin-left: 56px;
            }

            .contact_listing__contact_list__contact__container {
                width: 100%;
                overflow-y: scroll;

                .contact_listing__contact_list__contact {
                    width: 95%;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    cursor: pointer;
    
                    img {
                        width: 35px;
                        height: 35px;
                        margin-left: 20px;
                    }
    
                    .contact_listing__contact_list__name_container {
                        width: 100%;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background: $background-gray;
                        border-radius: 20px;
                        margin-left: 10px;
                        padding-left: 10px;
                        padding-right: 5px;

                        .contact_listing__contact_list__restore_container {
                            width: 100px;
                            height: 29px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: $bolded-gray;
                            box-shadow: 0px 2px 6px rgba(47, 83, 151, 0.1);
                            border-radius: 20px;
                            cursor: pointer;

                            span {
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 29px;
                                color: $white;
                            }
                        }
                    }
                }

                .contact_listing__contact_list__contact:last-of-type {
                    margin-bottom: 0px;
                }
            }
        }

        .contact_listing__contact_list_container__priority {
            background: $black;

            h2 {
                color: $white !important;
            }
        }
    }
}